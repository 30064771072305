const getApiUrls = () => {
  const CURR_URL = window.location.href;
  let baseRoot = "";
  if (CURR_URL.includes("storesystems-dev")) {
    baseRoot = "storesystems-dev.genpt";
  } else if (CURR_URL.includes("storesystems-qa")) {
    baseRoot = "storesystems-qa.genpt";
  } else if (CURR_URL.includes("storesystems")) {
    baseRoot = "storesystems.genpt";
  } else {
    baseRoot = "storesystems-dev.genpt";
  }
  return `https://${baseRoot}.com`;
};

export default getApiUrls;
