// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Box,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  FormControl,
  TextField,
  Button,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";
import GENERIC_CONSTANTS from "../../constants/Regulations/Generic";
import getApiHeaders from "../../services/utils.js/apiHeaders";
import RegulationApi from "../../services/RegulationsApi";
import DownloadTemplateButton from "../../components/DownloadTemplateButton";
import UploadPartListButton from "../../components/UploadPartListButton";
import {
  clearRegulationFormData,
  setUpdatedRegulationData,
} from "../../redux/regulationMaintenanceSlice";
import "./updateRegulations.css";
import getApiUrls from "../../services/utils.js/apiUrls";
import REGULATIONS_API_BASE from "../../services/axiosApi";

const updatedRegulations = ({ onCloseEditModal, isOpen }) => {
  const getBackendUrl = getApiUrls();
  const dispatch = useDispatch();
  const { editRegulationFormData, regulationParts, uploadPartsError } = useSelector(
    (state) => state.regulationMaintenanceForm,
  );
  const { authxToken, userName } = useSelector(
    (state) => state.loginToken,
  );
  const [spinner, setSpinner] = useState(false);
  const [showApiFailure, setShowApiFailure] = useState(false);
  const [showApiInfo, setShowApiInfo] = useState(false);
  const [apiFailureMessage, setApiFailureMessage] = useState("");
  const [showApiSuccess, setShowApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [apiInfoMessage, setApiInfoMessage] = useState("");
  const [effDatePickerVal, setEffDatePickerVal] = useState("");
  const [updatedRegulation, setUpdatedRegulation] = useState(
    editRegulationFormData,
  );
  const [intialRegulation, setIntialRegulation] = useState(
    editRegulationFormData,
  );
  const [filteredRegulatedParts, setFilteredRegulatedParts] = useState({});
  const [partsSearchString, setPartsSearchString] = useState("");
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isDataLossAlert, setIsDataLossAlert] = useState(false);
  const [partItemIds, setPartItemIds] = useState([]);
  const [inValidRows, setInValidRows] = useState([]);
  const [updatedParts, setUpdatedParts] = useState([]);
  const [uploadPartNumbers, setUploadPartNumbers] = useState([]);

  const handleDialogOpen = (dialogOpen) => {
    setIsDataLossAlert(dialogOpen);
  };

  const getFormattedDate = (dateVal) => {
    if (dayjs && typeof dayjs === "function") {
      return dayjs(dateVal).format("YYYY-MM-DD");
    }
    return dateVal;
  };

  const handleAlertClose = () => {
    setShowApiFailure(false);
    setApiFailureMessage("");
    setApiSuccessMessage("");
  };

  const handleDataDiscard = () => {
    dispatch(clearRegulationFormData());
    setIsFormUpdated(false);
    handleDialogOpen(false);
    handleAlertClose();
    onCloseEditModal();
  };

  const handleCloseEditModal = () => {
    if (isFormUpdated) {
      handleDialogOpen(true);
    } else {
      dispatch(clearRegulationFormData());
      handleAlertClose();
      onCloseEditModal();
    }
  };

  const handleSearchParts = (event) => {
    const partKeyword = event.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (partKeyword === "" || regex.test(partKeyword)) {
      setPartsSearchString(partKeyword);
    } else {
      return;
    }
    if (partKeyword) {
      const filteredRegulationParts = updatedRegulation.parts.filter(
        (item) => (`${item.lineAbbrev}${item.partNo}`).toLowerCase()
          .includes(partKeyword.toLowerCase().replaceAll(" ", ""))
          || item.partDesc.toLowerCase().includes(partKeyword.toLowerCase()),
      );
      const filteredupdatedRegulation = {
        ...updatedRegulation,
        parts: filteredRegulationParts,
      };
      setFilteredRegulatedParts(filteredupdatedRegulation);
    } else {
      setFilteredRegulatedParts(updatedRegulation);
    }
  };
  function removeDuplicatesPartsRows(fPartsData) {
    // Declare a new array
    const newUpdatedPartsArray = [];

    // Declare an empty object
    const uniquePartsObject = {};

    // Loop for the array elements
    Object.keys(fPartsData).forEach((key) => {
    // Extract the title
      const UpdatedNewParts = fPartsData[key].partNo;
      // Use the title as the index
      uniquePartsObject[UpdatedNewParts] = fPartsData[key];
    });
    Object.keys(uniquePartsObject).forEach((key) => {
    // Loop to push unique object into array{
      newUpdatedPartsArray.push(uniquePartsObject[key]);
    });
    // Display the unique objects
    return newUpdatedPartsArray;
  }

  const callUpdateRegulation = () => {
    const headers = getApiHeaders(authxToken.access_token);
    const config = {
      ...headers,
    };
    const apiCallback = (response) => {
      if (response.status === 200) {
        if (response && response.data && response.data.regId) {
          setSpinner(false);
          handleAlertClose();
          dispatch(clearRegulationFormData());
          dispatch(setUpdatedRegulationData({ value: response.data }));
          onCloseEditModal();
        }
      } else {
        setSpinner(false);
        setShowApiFailure(true);
        setApiFailureMessage(REGULATIONS_CONSTANTS.UPDATE_API_FAILURE);
      }
    };

    const duplicatePartsRows = removeDuplicatesPartsRows(updatedParts);
    RegulationApi.saveRegulation(updatedRegulation, config, apiCallback, "update", duplicatePartsRows);
  };

  const handleUpdateAndSubmit = () => {
    setSpinner(true);
    callUpdateRegulation();
  };

  const updatePartDetails = (params, val) => {
    const updatedPartList = [...updatedRegulation.parts];
    const updatedPartIndex = updatedRegulation.parts.findIndex((each) => each.id === params.id);
    updatedPartList[updatedPartIndex] = {
      ...updatedPartList[updatedPartIndex], [params.field]: val, modifiedBy: userName,
    };

    const upParts = [];
    updatedPartList.forEach((each) => {
      if (each.id === params.id || !each.regId) {
        upParts.push(each);
      }
    });

    const updatedNewParts = [...updatedParts, ...upParts];
    const updatedNewArryObject = {};

    Object.keys(updatedNewParts).forEach((key) => {
      if (updatedNewParts[key].id) {
        updatedNewArryObject[updatedNewParts[key].id] = updatedNewParts[key];
      }
    });
    const updatedNewPartsData = [];
    Object.keys(updatedNewArryObject).forEach((key) => {
      updatedNewPartsData.push(updatedNewArryObject[key]);
    });

    setUpdatedParts([...regulationParts, ...updatedNewPartsData]);

    const updatedRegAfterUpdate = {
      ...updatedRegulation,
      parts: updatedPartList,
    };
    setUpdatedRegulation(updatedRegAfterUpdate);
    setFilteredRegulatedParts(updatedRegAfterUpdate);
  };

  const handleDatePickerSelection = (val, params) => {
    if (val) {
      setIsFormUpdated(true);
      const formattedDateVal = getFormattedDate(val);
      if (params.field === "effDate") {
        setEffDatePickerVal(val);
        setUpdatedRegulation({
          ...updatedRegulation,
          [params.field]: formattedDateVal,
          modifiedBy: userName,
        });
      } else if (params.field === "expiredDate" || params.field === "obsoleteDate") {
        updatePartDetails(params, formattedDateVal);
      }
    }
  };

  const handleFormUpdateByKey = (e, params) => {
    const val = e.target.value;
    if (params.field === "bestClass") {
      updatePartDetails(params, val);
    } else {
      setUpdatedRegulation({ ...updatedRegulation, [params.field]: val, modifiedBy: userName });
    }
    setIsFormUpdated(true);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const getRegulatedPartsForGrid = () => {
    const regulatedPartsForGrid = updatedRegulation.parts.map((each) => ({
      ...each,
      id: `${each.partNo}-${each.partDesc}-${each.lineAbbrev}`,
      createdDate: each.createdDate ? getFormattedDate(each.createdDate) : "",
      modifiedDate: each.modifiedDate ? getFormattedDate(each.modifiedDate) : "",
    }));
    const filteredUpdatedRegulation = {
      ...updatedRegulation,
      parts: regulatedPartsForGrid,
    };

    if (dayjs && typeof dayjs === "function") {
      setEffDatePickerVal(dayjs(editRegulationFormData.effDate));
    }
    setIntialRegulation(filteredUpdatedRegulation);
    setUpdatedRegulation(filteredUpdatedRegulation);
    setFilteredRegulatedParts(filteredUpdatedRegulation);
  };

  const validatePartsByCatalog = () => {
    const regulatedPartsPID = regulationParts.map((each) => ((each.lineAbbrev).length === 2 ? `${each.lineAbbrev}_${each.partNo}` : each.lineAbbrev + each.partNo));
    setPartItemIds(regulatedPartsPID);
    const regulatedPartsNos = regulationParts.map((each) => each.partNo);
    setUploadPartNumbers(regulatedPartsNos);
  };

  const getUploadedFileData = (fPartsData) => {
    const fPartsDataFrom = fPartsData;
    fPartsDataFrom.forEach((fpart, index) => {
      const part = regulationParts.find((item) => item.partNo === fpart.partNo);
      if (part && fPartsDataFrom[index].regId) {
        let isModified = false;

        if (!Object.prototype.hasOwnProperty.call(part, "altLineAbbrev")) {
          /**
           * 1. In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].altLineAbbrev = null;
        } else if (part?.altLineAbbrev
          && fPartsDataFrom[index].altLineAbbrev !== part?.altLineAbbrev) {
          /** Only update the master data incase the new data has an updated value */
          fPartsDataFrom[index].altLineAbbrev = part.altLineAbbrev;
          isModified = true;
        }

        if (!Object.prototype.hasOwnProperty.call(part, "altPartNumber")) {
          /**
           * In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].altPartNumber = null;
        } else if (part?.altPartNumber
          && fPartsDataFrom[index].altPartNumber !== part?.altPartNumber) {
          fPartsDataFrom[index].altPartNumber = part.altPartNumber;
          isModified = true;
        }

        if (!Object.prototype.hasOwnProperty.call(part, "altPartNumber")) {
          /**
           * 1. In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].altPartDesc = null;
        } else if (part?.altPartDesc && fPartsDataFrom[index].altPartDesc !== part?.altPartDesc) {
          fPartsDataFrom[index].altPartDesc = part.altPartDesc;
          isModified = true;
        }

        if (!Object.prototype.hasOwnProperty.call(part, "bestClass")) {
          /**
           * 1. In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].bestClass = null;
        } else if (part?.bestClass && fPartsDataFrom[index].bestClass !== part?.bestClass) {
          fPartsDataFrom[index].bestClass = part.bestClass;
          isModified = true;
        }

        if (!Object.prototype.hasOwnProperty.call(part, "lineAbbrev")) {
          /**
           * 1. In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].lineAbbrev = null;
        } else if (part?.lineAbbrev && fPartsDataFrom[index].lineAbbrev !== part?.lineAbbrev) {
          fPartsDataFrom[index].lineAbbrev = part.lineAbbrev;
          isModified = true;
        }

        if (!Object.prototype.hasOwnProperty.call(part, "partDesc")) {
          /**
           * 1. In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].partDesc = null;
        } else if (fPartsDataFrom[index].partDesc
          && fPartsDataFrom[index].partDesc !== part?.partDesc) {
          fPartsDataFrom[index].partDesc = part.partDesc;
          isModified = true;
        }

        if (!Object.prototype.hasOwnProperty.call(part, "partNo")) {
          /**
           * 1. In case uploaded data deleted the property of a column
           *  1. we set the corresponding master data key as null
           *  2. also, mark the master data value as modified
           * */
          fPartsDataFrom[index].partNo = null;
        } else if (fPartsDataFrom[index].partNo && fPartsDataFrom[index].partNo !== part?.partNo) {
          fPartsDataFrom[index].partNo = part.partNo;
          isModified = true;
        }
        if (fPartsDataFrom[index].regId && isModified) {
          fPartsDataFrom[index].modifiedBy = userName;
        }
      }
      setUpdatedParts([...regulationParts, ...fPartsDataFrom]);
    });
  };

  useEffect(() => {
    if (partItemIds?.length > 0) {
      setShowApiFailure(false);
      setShowApiInfo(false);
      setShowApiSuccess(false);
      setApiFailureMessage("");
      setApiInfoMessage("");
      setApiSuccessMessage("");
      setInValidRows([]);
      const inValidRowsIndexes = [];
      const validRowsIndexes = [];
      let isSuccess = false;
      let isFailure = false;

      let errorsLength = 0;
      const failurePartIds = [];
      updatedRegulation.parts.forEach((fpart) => {
        const part = regulationParts.find((item) => item.partNo === fpart.partNo);
        if (part) {
          if (getFormattedDate(part.obsoleteDate) === "Invalid Date") {
            const partId = (part.lineAbbrev).length === 2 ? `${part.lineAbbrev}_${part.partNo}` : part.lineAbbrev + part.partNo;
            failurePartIds.push(partId);
            errorsLength += 1;
            isFailure = true;
            inValidRowsIndexes.push(part.partNo);
          }
        }
      });
      const chunkSize = 50;
      for (let i = 0, len = partItemIds?.length; i < len; i += chunkSize) {
        const chunk = partItemIds.slice(i, i + chunkSize);
        let pIds = JSON.stringify(chunk);
        pIds = pIds.replaceAll("[", "");
        pIds = pIds.replaceAll("]", "");
        pIds = pIds.replaceAll(",", " ");
        setUpdatedRegulation(intialRegulation);
        const exsitingParts = [];
        intialRegulation.parts.forEach((ep) => {
          exsitingParts.push(ep.partNo);
        });

        const queryData = `query { itemLookUp(input: { itemIds: [${pIds}] filterCondition: { country: US lang: EN isFieldLine: true } } ) { itemErrors { message errorCode itemId } items { title lineAbbreviation partNumber } } }`;
        REGULATIONS_API_BASE.post(`${getBackendUrl}/proxycatalog/getCatalogData`, { query: queryData })
          // eslint-disable-next-line no-loop-func
          .then((partsData) => {
            if (partsData.data.data && partsData.data.data.itemLookUp) {
              const fPartsData = updatedRegulation;

              getUploadedFileData(fPartsData.parts);

              const filterPartsData = { ...updatedRegulation, parts: fPartsData.parts };
              if (partsData.data.data.itemLookUp.itemErrors
              && partsData.data.data.itemLookUp.itemErrors.length > 0) {
                isFailure = true;
                partsData.data.data.itemLookUp.itemErrors.forEach((pr) => {
                  if (failurePartIds.indexOf(pr.itemId) > -1) {
                    errorsLength -= 1;
                  }
                });
              }

              if (partsData.data.data.itemLookUp.items
               && partsData.data.data.itemLookUp.items.length > 0) {
                isSuccess = true;

                const sItems = partsData.data.data.itemLookUp.items;
                filterPartsData.parts.forEach((fpart, index) => {
                  const ptNumber = filterPartsData.parts[index].partNo;
                  const pdata = sItems.filter((pt) => pt.partNumber === ptNumber);
                  if (pdata && pdata.length > 0) {
                    if (validRowsIndexes.indexOf(pdata[0].partNumber) === -1) {
                      validRowsIndexes.push(pdata[0].partNumber);
                    }

                    filterPartsData.parts[index].partNo = pdata[0].partNumber;
                    if (exsitingParts.indexOf(filterPartsData.parts[index].partNo) === -1) {
                      filterPartsData.parts[index].createdBy = userName;
                    }
                  } else if (exsitingParts.indexOf(filterPartsData.parts[index].partNo) === -1
                      && inValidRowsIndexes.indexOf(filterPartsData.parts[index].partNo) === -1) {
                    inValidRowsIndexes.push(filterPartsData.parts[index].partNo);
                  } else if (exsitingParts.indexOf(filterPartsData.parts[index].partNo) > -1
                      && uploadPartNumbers.indexOf(filterPartsData.parts[index].partNo) > -1) {
                    inValidRowsIndexes.push(filterPartsData.parts[index].partNo);
                  }
                });
              }
              if (!isSuccess) {
                filterPartsData.parts.forEach((fpart, index) => {
                  if (exsitingParts.indexOf(filterPartsData.parts[index].partNo) === -1
                    && validRowsIndexes.indexOf(filterPartsData.parts[index].partNo) === -1
                    && inValidRowsIndexes.indexOf(filterPartsData.parts[index].partNo) === -1) {
                    inValidRowsIndexes.push(filterPartsData.parts[index].partNo);
                    setInValidRows(inValidRowsIndexes);
                  }
                });
              }

              if (isSuccess && !isFailure) {
                setApiSuccessMessage(`${partItemIds?.length ? partItemIds?.length : ""}
                parts loaded. Click update to process parts.`);
                setShowApiSuccess(true);
                const upParts = [];
                filterPartsData.parts.forEach((fpart, index) => {
                  if (exsitingParts.indexOf(filterPartsData.parts[index].partNo) === -1) {
                    upParts.push(filterPartsData.parts[index]);
                  }
                });
                setUpdatedParts([...filterPartsData.parts, ...upParts]);
                const filteredUpdatedRegulation = {
                  ...updatedRegulation,
                  parts: filterPartsData.parts,
                };
                setIntialRegulation(filteredUpdatedRegulation);
                setUpdatedRegulation(filteredUpdatedRegulation);
                setFilteredRegulatedParts(filteredUpdatedRegulation);
              }

              if (!isSuccess && isFailure) {
                setApiFailureMessage(
                  `${errorsLength + partsData.data.data.itemLookUp.itemErrors.length} 
                parts not loaded. Revisit your parts and try to upload again.`,
                );
                setShowApiFailure(true);
              }

              if (isSuccess && isFailure) {
                const toalCount = partsData.data.data.itemLookUp.itemErrors.length === 0
                  ? partsData.data.data.itemLookUp.items.length
                  : (partsData.data.data.itemLookUp.items.length
                                   + partsData.data.data.itemLookUp.itemErrors.length);
                setApiFailureMessage(
                  `${errorsLength + partsData.data.data.itemLookUp.itemErrors.length} out of ${toalCount} parts not loaded correctly. Revisit your parts and try to upload again.`,
                );
                setShowApiFailure(true);
                setIsFormUpdated(false);
                setInValidRows(inValidRowsIndexes);
              }
            }
          })
          .catch((error) => {
            setApiFailureMessage(error.message); setShowApiFailure(true);
          });
      }
    }
  }, [partItemIds]);

  useEffect(() => {
    if (uploadPartsError && Object.keys(uploadPartsError)[0] === "regulationParts" && uploadPartsError.regulationParts) {
      const errorMsg = uploadPartsError.regulationParts
        ? uploadPartsError.regulationParts : REGULATIONS_CONSTANTS.UNABLE_READ_FILE;
      setApiFailureMessage(errorMsg);
      setShowApiFailure(true);
    }
  }, [uploadPartsError]);

  useEffect(() => {
    if (regulationParts && regulationParts.length > 0) {
      setIsFormUpdated(true);
      const regulatedParts = regulationParts.map((each) => ({
        ...each,
        obsoleteDate: each.obsoleteDate ? getFormattedDate(each.obsoleteDate) : each.obsoleteDate,
        id: `${each.partNo}-${each.partDesc}-${each.lineAbbrev}`,
      }));
      const mergedParts = [...updatedRegulation.parts, ...regulatedParts];
      const uniqueParts = mergedParts.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.partNo === current.partNo) && current.partNo) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);

      setUpdatedRegulation({
        ...updatedRegulation,
        parts: uniqueParts,
      });
      setFilteredRegulatedParts({
        ...updatedRegulation,
        parts: uniqueParts,
      });
      handleAlertClose();
      validatePartsByCatalog();
    }
  }, [regulationParts]);

  useEffect(() => {
    getRegulatedPartsForGrid();
  }, []);

  const createdDatePickerVal = updatedRegulation.createdDate
    ? getFormattedDate(updatedRegulation.createdDate)
    : "";
  const modifiedDatePickerVal = updatedRegulation.modifiedDate
    ? getFormattedDate(updatedRegulation.modifiedDate)
    : "";
  const {
    REGULATION_HEADER_LABELS,
    REGULATION_PARTS_LABELS,
    REGULATION_DETAILS_HEADER_LABELS,
    REG_CLASS_CODES,
  } = REGULATIONS_CONSTANTS;

  const detailsTableColumns = useMemo(() => [
    {
      field: REGULATIONS_CONSTANTS.REG_TITLE,
      headerName: REGULATION_HEADER_LABELS[0],
      width: 300,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_DESC,
      headerName: REGULATION_DETAILS_HEADER_LABELS[0],
      width: 250,
      renderCell: (params) => (
        <TextField
          id="update-reg-desc"
          className="editableTextField"
          color="warning"
          variant="standard"
          focused
          value={params.value}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleFormUpdateByKey(e, params)}
        />
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.RULE_NO,
      headerName: REGULATION_HEADER_LABELS[1],
      width: 110,
      renderCell: (params) => (
        <TextField
          id="update-reg-rule"
          className="editableTextField"
          color="warning"
          variant="standard"
          focused
          value={params.value}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleFormUpdateByKey(e, params)}
        />
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.REG_JURISDICTION_LEVEL,
      headerName: REGULATION_HEADER_LABELS[2],
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.STATE_CODE,
      headerName: REGULATION_HEADER_LABELS[3],
      width: 100,
    },
    {
      field: REGULATIONS_CONSTANTS.EFF_DATE,
      headerName: REGULATION_HEADER_LABELS[8],
      width: 140,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=""
            value={effDatePickerVal}
            format="YYYY-MM-DD"
            onChange={(val) => handleDatePickerSelection(val, params)}
            slotProps={{ textField: { variant: "standard", color: "warning", focused: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.REG_TYPE,
      headerName: REGULATION_HEADER_LABELS[4],
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_DATE,
      headerName: REGULATION_DETAILS_HEADER_LABELS[6],
      width: 135,
      renderCell: () => (<span>{createdDatePickerVal}</span>),
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_BY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[7],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_DATE,
      headerName: REGULATION_DETAILS_HEADER_LABELS[8],
      width: 135,
      renderCell: () => (<span>{modifiedDatePickerVal}</span>),
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_BY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[9],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_COUNTRY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[10],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_COUNTY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[11],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_CITY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[12],
      width: 130,
    },
  ], []);

  const partsTableColumns = useMemo(() => [
    {
      field: REGULATIONS_CONSTANTS.PART_ABBR,
      headerName: REGULATIONS_CONSTANTS.FIELD_LINE,
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_NO,
      headerName: REGULATION_PARTS_LABELS[1],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_DESC,
      headerName: REGULATION_PARTS_LABELS[2],
      width: 250,
    },
    {
      field: REGULATIONS_CONSTANTS.EXPIRED_DATE,
      headerName: REGULATION_PARTS_LABELS[12],
      width: 150,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=""
            value={params.value
              ? dayjs(params.value) : ""}
            format="YYYY-MM-DD"
            onChange={(val) => handleDatePickerSelection(val, params)}
            slotProps={{ textField: { variant: "standard", color: "warning", focused: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.OBSOLETE_DATE,
      headerName: REGULATION_PARTS_LABELS[3],
      width: 150,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=""
            value={params.value
              ? dayjs(params.value) : ""}
            format="YYYY-MM-DD"
            onChange={(val) => handleDatePickerSelection(val, params)}
            slotProps={{ textField: { variant: "standard", color: "warning", focused: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.BEST_CLASS,
      headerName: REGULATION_PARTS_LABELS[11],
      width: 150,
      renderCell: (params) => (
        <TextField
          id="update-reg-classCode"
          select
          value={(params && params.value) ? params.value.toUpperCase() : ""}
          onChange={(e) => handleFormUpdateByKey(e, params)}
          variant="standard"
          color="warning"
          focused
          fullWidth
        >
          {REG_CLASS_CODES
          && REG_CLASS_CODES.map((eachCode) => (
            <MenuItem
              key={`regClassCode-${eachCode}`}
              value={eachCode}
            >
              {eachCode}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_LINE_ABBR,
      headerName: REGULATION_PARTS_LABELS[4],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_PART_NO,
      headerName: REGULATION_PARTS_LABELS[5],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_PART_DESC,
      headerName: REGULATION_PARTS_LABELS[6],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_DATE,
      headerName: REGULATION_PARTS_LABELS[7],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_BY,
      headerName: REGULATION_PARTS_LABELS[8],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_DATE,
      headerName: REGULATION_PARTS_LABELS[9],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_BY,
      headerName: REGULATION_PARTS_LABELS[10],
      width: 150,
    },
  ], [filteredRegulatedParts.parts]);

  const getRowClassName = (params) => (inValidRows.indexOf(params.row.partNo) > -1 ? "inValidRow" : "");

  return (
    <div className="update-regulations-container">
      <Drawer
        sx={{ width: "80% !important" }}
        anchor="right"
        open={isOpen}
        onClose={onCloseEditModal}
      >
        {spinner && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!spinner && (
          <div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <CloseIcon onClick={handleCloseEditModal} />
            </Grid>
            {showApiSuccess && (
              <Alert
                severity="success"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
                onClose={handleAlertClose}
              >
                {apiSuccessMessage}
              </Alert>
            )}
            {showApiFailure && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
                onClose={handleAlertClose}
              >
                {apiFailureMessage}
              </Alert>
            )}
            {showApiInfo && (
              <Alert
                severity="info"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
                onClose={handleAlertClose}
              >
                {apiInfoMessage}
              </Alert>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div className="update-regulation-form">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="stepper-content-title"
                >
                  {REGULATIONS_CONSTANTS.UPDATE_REGULATION}
                </Typography>
                <Divider />
                {updatedRegulation && updatedRegulation.regId && (
                  <div>
                    <div className="regulaion-details-section">
                      <p className="stepper-content-subTitle">
                        {`${REGULATIONS_CONSTANTS.STEPPER_TITLES_EDIT[0]} - ${REGULATIONS_CONSTANTS.STEPPER_SUB_TITLES_EDIT[0]}`}
                      </p>
                      <DataGrid
                        rows={[updatedRegulation]}
                        columns={detailsTableColumns}
                        hideFooter
                        hideFooterPagination
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                      />
                    </div>
                    <Divider />
                    <div className="update-regulated-parts-section">
                      <p className="stepper-content-subTitle">
                        {`${REGULATIONS_CONSTANTS.STEPPER_TITLES_EDIT[1]} - ${REGULATIONS_CONSTANTS.STEPPER_SUB_TITLES_EDIT[1]}`}
                      </p>
                      <div>
                        <div className="update-parts-filter-section">
                          {updatedRegulation.parts && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <FormControl
                              sx={{ width: 500, backgroundColor: "#fff", padding: "0px" }}
                            >
                              <TextField
                                id="reg-search"
                                type="search"
                                variant="standard"
                                value={partsSearchString}
                                onChange={handleSearchParts}
                                label={
                                    REGULATIONS_CONSTANTS.SEARCH_PLACEHOLDER
                                  }
                              />
                            </FormControl>
                          </Grid>
                          )}
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <DownloadTemplateButton />
                            <UploadPartListButton />
                          </Grid>
                        </div>
                        {filteredRegulatedParts
                            && filteredRegulatedParts.parts
                            && filteredRegulatedParts.parts.length > 0 && (
                              <div className="uploaded-parts-table">
                                <DataGrid
                                  rows={filteredRegulatedParts.parts}
                                  columns={partsTableColumns}
                                  initialState={{
                                    pagination: {
                                      paginationModel: {
                                        pageSize: 10,
                                      },
                                    },
                                  }}
                                  pageSizeOptions={[10]}
                                  disableRowSelectionOnClick
                                  disableColumnSelector
                                  disableColumnMenu
                                  autoHeight
                                  classes={{ withBorderColor: "#001489" }}
                                  getRowClassName={getRowClassName}
                                />
                              </div>
                        )}
                      </div>

                      {partsSearchString
                        && filteredRegulatedParts
                        && filteredRegulatedParts.parts
                        && filteredRegulatedParts.parts.length === 0 && (
                          <Alert severity="info">
                            {REGULATIONS_CONSTANTS.NO_SEARCH_PARTS_RESULTS}
                          </Alert>
                      )}
                      {!partsSearchString
                        && filteredRegulatedParts
                        && filteredRegulatedParts.parts
                        && filteredRegulatedParts.parts.length === 0 && (
                          <Alert severity="info">
                            {REGULATIONS_CONSTANTS.NO_PARTS_AVAILABLE}
                          </Alert>
                      )}
                    </div>
                  </div>
                )}
                <div className="stepper-actions">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button
                      className={(!isFormUpdated || inValidRows.length > 0) ? "continue-btn-disabled" : "continue-btn"}
                      variant="contained"
                      onClick={handleUpdateAndSubmit}
                      disabled={!isFormUpdated || inValidRows.length > 0}
                    >
                      { GENERIC_CONSTANTS.UPDATE}
                    </Button>
                  </Grid>
                </div>
              </div>
            </Grid>
          </div>
        )}
        {isDataLossAlert && (
          <div>
            <Dialog
              open={isDataLossAlert}
              onClose={() => handleDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
                {REGULATIONS_CONSTANTS.DATA_DISCARD_ALERT_TITLE}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" className="alert-dialog-description">
                  {REGULATIONS_CONSTANTS.DATA_DISCARD_ALERT_MSG}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="dialog-button-primary" variant="contained" onClick={() => handleDataDiscard(false)}>{GENERIC_CONSTANTS.DISCARD}</Button>
                <Button className="dialog-button-secondary" variant="contained" onClick={() => handleDialogOpen(false)} autoFocus>
                  {GENERIC_CONSTANTS.CANCEL}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default updatedRegulations;
