import dayjs from "dayjs";
import REGULATIONS_API_BASE from "./axiosApi";

const RegulationApi = {
  getRegulations: (apiInput, apiCallback) => {
    const apiUrl = "/proxy/v1/regulations/paged";
    REGULATIONS_API_BASE.get(apiUrl, apiInput)
      .then((response) => {
        if (response.status === 200) {
          apiCallback(response);
        }
      })
      .catch((error) => {
        apiCallback(error);
      });
  },
  getGeolocationDataByParam: (apiInput, apiCallback) => {
    const apiUrl = "/proxy/v1/geolocation";
    REGULATIONS_API_BASE.get(apiUrl, apiInput)
      .then((response) => {
        if (response.status === 200) {
          apiCallback(response);
        }
      })
      .catch((error) => {
        apiCallback(error);
      });
  },
  getFormattedDate: (dateVal) => {
    if (dayjs && typeof dayjs === "function") {
      return dayjs(dateVal).format("YYYY-MM-DD");
    }
    return dateVal;
  },
  saveRegulation: (apiInput, config, apiCallback, action = "save", updatedParts = []) => {
    const apiUrl = "/proxy/v1/save-regulation";
    const parts = [];
    if (action === "save") {
      apiInput.parts.forEach((part, index) => {
        const obj = {
          ...apiInput.parts[index],
          partDesc: (apiInput.parts[index].partDesc)?.substr(0, 39),
          createdBy: apiInput.createdBy,
        };
        parts.push(obj);
      });
    }
    const regData = {};
    Object.keys(apiInput).forEach((key) => {
      if (key === "parts") {
        if (action === "save") {
          regData[key] = parts;
        } else if (updatedParts.length === 0) {
          regData[key] = updatedParts;
        } else {
          regData[key] = [];
          const uParts = (updatedParts && updatedParts.length > 0) ? updatedParts : [];
          if (uParts && uParts.length > 0) {
            uParts.forEach((part) => {
              const p = { ...part, partDesc: (part.partDesc)?.substr(0, 39) };
              delete p.modifiedDate;
              delete p.createdDate;
              regData[key].push(p);
            });
          } else {
            regData[key] = apiInput[key];
          }
        }
      } else if (key === "modifiedBy" || key === "modifiedDate") {
        if (apiInput[key] != null && apiInput[key] !== "") {
          regData[key] = apiInput[key];
        }
      } else {
        regData[key] = apiInput[key];
      }
    });
    delete regData.modifiedDate;
    delete regData.createdDate;
    const rData = { ...regData };
    rData.parts.forEach((fpart, index) => {
      const part = apiInput.parts.find((item) => item.partNo === fpart.partNo);
      if (part
      && (rData.parts[index].altLineAbbrev !== part.altLineAbbrev
      || rData.parts[index].altPartNumber !== part.altPartNumber
      || rData.parts[index].altPartDesc !== part.altPartDesc
      || rData.parts[index].bestClass !== part.bestClass
      || rData.parts[index].lineAbbrev !== part.lineAbbrev
      || rData.parts[index].obsoleteDate !== part.obsoleteDate)) {
        rData.parts[index].altLineAbbrev = part.altLineAbbrev;
        rData.parts[index].altPartNumber = part.altPartNumber;
        rData.parts[index].altPartDesc = part.altPartDesc;
        rData.parts[index].bestClass = part.bestClass;
        rData.parts[index].lineAbbrev = part.lineAbbrev;
        rData.parts[index].obsoleteDate = part.obsoleteDate;
      }
    });
    REGULATIONS_API_BASE.post(apiUrl, { ...regData, parts: rData.parts }, config)
      .then((response) => {
        if (response.status === 200) {
          apiCallback(response);
        }
      })
      .catch((error) => {
        apiCallback(error);
      });
  },
};

export default RegulationApi;
