const defaultConfig = {
  environmentId: "18905651-be30-4313-a398-3f24076969c3",
  responseType: "code",
  grantType: "authorization_code",
  redirectUri: "http://localhost:8080",
  logoutRedirectUri: "http://localhost:8080",
  scope: "openid profile address email phone",
  prompt: "login",
  tokenEndpointAuthMethod: "client_secret_post",
  maxAge: 3600,
  BASE_URL: "https://api.pingone.com",
  API_URI: "https://api.pingone.com/v1",
  AUTH_URI: "https://auth.pingone.com",
  allowedProxyUrls: [
    "https://storesystems-dev.genpt.com",
    "https://storesystems-qa.genpt.com",
    "https://storesystems.genpt.com",
    "https://api.pingone.com",
    "https://api.pingone.com/v1",
    "https://auth.pingone.com",
  ],
};

const getPingConfig = () => {
  const CURR_URL = window.location.href;
  if (CURR_URL.includes("storesystems-dev")) {
    return {
      ...defaultConfig,
      redirectUri: "https://storesystems-dev.genpt.com",
      logoutRedirectUri: CURR_URL,
    };
  }
  if (CURR_URL.includes("storesystems-qa")) {
    return {
      ...defaultConfig,
      redirectUri: "https://storesystems-qa.genpt.com",
      logoutRedirectUri: CURR_URL,
    };
  }
  if (CURR_URL.includes("storesystems.genpt")) {
    return {
      ...defaultConfig,
      redirectUri: "https://storesystems.genpt.com",
      logoutRedirectUri: CURR_URL,
      environmentId: "d7a48707-7aff-411c-9da1-91490ca4c003",
    };
  }
  return {
    ...defaultConfig,
    redirectUri: "http://localhost:3000",
    logoutRedirectUri: "http://localhost:3000",
  };
};

export default getPingConfig;
