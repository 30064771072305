import axios from "axios";
import getApiUrls from "./utils.js/apiUrls";

const getBackendUrl = getApiUrls();

const REGULATIONS_API_BASE = axios.create({
  baseURL: getBackendUrl,
});

export default REGULATIONS_API_BASE;
